// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "\n", "",{"version":3,"sources":[],"names":[],"mappings":"","file":"colors.css"}]);
// Exports
exports.locals = {
	"lightblue": "hsla(186, 70, 40, 1)",
	"yellow": "#bafd31",
	"offwhite": "#d7ffff",
	"darkgray": "RGBA(61,61,61,1.00)",
	"white": "RGBA(255,255,255,1)"
};
module.exports = exports;
